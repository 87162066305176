import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import FixedRatioImage from '../../Components/FixedRatioImage';

function AboutSection(props) {
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';

  return (
    <Wrapper mobile={mobile}>
      <Container mobile={mobile}>
        <FixedRatioImage
          width="100%"
          ratio={690 / 927}
          image="../../images/landing-about.jpg"
          mode="cover"
        />
      </Container>
      <Container mobile={mobile}>
        <div className="content">
          <h3>關於微媞生技</h3>
          <p>
            {`專業研發團隊運用豐富臨床經驗且深入研究肌膚構造，針對亞洲肌膚特性與需求，研發出一系列醫美級居家修護保養品。

堅持「低敏感、高濃度、高效能、高安全性」為四大研發標準，是敏感膚質與醫學美容及雷射保養後、居家修護的最佳選擇。延續醫美療程護膚機制，在家就能輕鬆實踐速效保養，使醫美效果更升級!
We Care For Your Beauty, Create For Your Infinity.`}
          </p>
        </div>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  /* margin-top: var(--topNavBarHeight); */
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  max-width: 960px;
  width: 100%;
  background-color: var(--primaryColor);

  & > .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: var(--primaryColor);
    padding: 15px;

    & > h3 {
      width: 100%;
      padding-bottom: 25px;
      border-bottom: 2px solid #ffffff;
      font-weight: 400;

      text-align: center;
      font-size: 32px;
      color: #ffffff;
    }

    & > p {
      width: 100%;
      padding: 25px;
      text-align: center;
      font-size: 15px;
      color: #ffffff;
      font-weight: 400;
      line-height: 2;
    }

    @media screen and (max-width: 550px) {
      padding: 20px;

      & > h3 {
        padding-bottom: 15px;
        font-size: 24px;
      }

      & > p {
        padding-top: 15px;
        font-size: 14px;
        line-height: normal;
      }
    }
  }
`;

export default AboutSection;
