import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {LocationOn} from '@styled-icons/material/LocationOn';
import withPageEntry from '../../withPageEntry';
import AboutSection from './AboutSection';

function About(props) {
  const [dimension] = useOutlet('dimension');
  const [bannerDimension, setBannerDimension] = React.useState(null);
  const desktop = dimension.rwd === 'desktop';

  React.useLayoutEffect(() => {
    const elem = document.getElementById('rev-banner');
    const box = elem.getBoundingClientRect();
    setBannerDimension(box);
  }, [dimension.innerWidth]);

  return (
    <Wrapper rwd={dimension.rwd} dimension={dimension} id="vb-AboutPage">
      <div
        id="rev-banner"
        className="banner"
        style={{
          height: (bannerDimension?.width * 760) / 1920, // 1920*760
        }}>
        <img
          alt="about-banner-1"
          src="../../images/about-banner.jpg"
          style={{width: '100%', height: '100%', objectFit: 'cover'}}
        />
      </div>
      <section className={desktop ? `section` : `section mobile`} id="intro">
        <div className="constrain-content">
          <div className={desktop ? 'subtitle' : 'subtitle mobile'}>ABOUT</div>
          <div className="title">品牌介紹</div>
          <div
            className={desktop ? 'carousel-wrapper' : 'carousel-wrapper mobile'}
            style={{marginTop: 20}}>
            <img
              alt="about-brand"
              src="../../images/about.jpg"
              style={{
                objectFit: 'cover',

                width:
                  dimension.rwd === 'desktop'
                    ? (dimension.innerWidth - 200) / 3
                    : dimension.innerWidth - 40,
                height:
                  dimension.rwd === 'desktop'
                    ? (dimension.innerWidth - 200) / 4
                    : (dimension.innerWidth - 40) / 1.5,
                borderRadius: 20,
              }}
            />
            <div style={{flexBasis: 40}} />
            <div style={{flex: 1}}>
              <div className="title">關於微媞</div>
              <p
                className="content"
                style={{marginTop: 40, whiteSpace: 'break-spaces'}}>
                {`微媞生技-醫美專業修護保養品，數種國際認證之專利成分，能減少肌膚乾燥缺水的現象，有效提升肌膚修護力及保濕度，舒緩肌膚不適，降低肌膚負擔，還原美肌。居家修護，延續醫美療程護膚機制，在家就能輕鬆實踐速效保養，醫美效果更升級。`}
              </p>
            </div>
          </div>
        </div>
      </section>

      <AboutSection />

      <section
        className={desktop ? 'section-dark' : 'section-dark mobile'}
        id="consult">
        <div className="constrain-content">
          <div className="title">購物需知</div>
          <p
            className="content"
            style={{
              marginTop: 40,
            }}>
            {`微媞網購商城—提供高品質的產品與服務。若您有退換貨的服務需求，請至聯絡我們頁面或撥打客服電話02-8771-8830與我們聯繫，我們非常樂意在7天鑑賞期內為您提供退換貨服務。\n\n`}
            <div style={{fontWeight: 'bold'}}>{`請注意！`}</div>
            {`如為退換貨商品必須：\n1.包裝完整\n2.附上原始發票\n3.如購買時有相關贈品、文件資料請一併附上。\n\n退換貨服務除了商品本身破損外，其餘情況運費皆需由買家自行負擔。基於衛生因素考量，個人用品拆封後恕無接受退換貨服務，敬請見諒。`}
          </p>
        </div>
      </section>
      <div
        id="rev-banner"
        className="banner"
        style={{height: bannerDimension?.width * 0.38}}>
        <img
          alt="about-banner-2"
          src="../../images/about-banner2.jpg"
          style={{width: '100%', height: '100%', objectFit: 'cover'}}
        />
      </div>

      <section
        className={desktop ? 'section-dark' : 'section-dark mobile'}
        id="contact">
        <div className="constrain-content">
          <div
            className={
              desktop ? 'carousel-wrapper' : 'carousel-wrapper mobile'
            }>
            <div style={{flex: 1}}>
              <div className="row">
                <LocationOn size={30} />
                <div style={{marginLeft: 12, flex: 1}}>
                  <div className="title">微媞生技</div>
                  <p className="content" style={{marginTop: 20}}>
                    {`客服電話：02-8771-8830\n客服信箱：contact@vbeautygroup.com\n營業時間：週一至週五 09:30～18:30\n (不含國定例假日)\n地址：台北市大安區忠孝東路四段250號3樓`}
                  </p>
                </div>
              </div>
            </div>
            <div style={{flexBasis: 20}} />
            <div style={{flex: 1, backgroundColor: '#eee'}}>
              <iframe
                title="vb-map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.7925115794!2d121.55197895056362!3d25.041114583890376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442abc670875589%3A0x1780385130482511!2z5b6u5aqe5pmC5bCa6Ki65omA!5e0!3m2!1sen!2stw!4v1633439401406!5m2!1sen!2stw"
                width="100%"
                height="100%"
                style={{border: 0}}
                allowFullScreen={true}
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: var(--topNavBarHeight);

  & > .banner {
    width: 100%;
    height: 600px;
  }
`;

export default withPageEntry(About);
